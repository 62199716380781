











































import {
  ApiGetApplicationFormResponseDto,
  ApiGetApplicationFormTemplateDto,
  ApiGetCustomerDto,
} from "@/api/generated/Api";
import ParticipantAttendance from "@/components/course/details/participant/ParticipantAttendance.vue";
import ParticipantGeneralInfo from "@/components/course/details/participant/ParticipantGeneralInfo.vue";
import ParticipantWebApplication from "@/components/course/details/participant/ParticipantWebApplication.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { useRestrictedAccessApi } from "@/shared/helpers/accessLevelApiAdapter";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref, watch } from "@vue/composition-api";
import { mapGetters } from "vuex";

export enum CourseParticipantTabNames {
  Personal = "personal",
  Appform = "appform",
  Attendence = "attendence",
}

export default defineComponent({
  name: "CourseParticipantDetailPage",
  components: {
    ParticipantGeneralInfo,
    ParticipantWebApplication,
    BaseLayout,
    ParticipantAttendance,
  },
  computed: {
    ...mapGetters("courses", ["getCourse"]),
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const restrictedAccessApi = useRestrictedAccessApi();
    const contact = ref<ApiGetCustomerDto>();
    const applicationInfo = ref<ApiGetApplicationFormResponseDto>();
    const applicationForm = ref<ApiGetApplicationFormTemplateDto>();
    const store = useStore<StoreState>();

    const userAppliedViaForm = route.query.appliedViaForm === "true";
    const activeTab = ref(route.query.tab || CourseParticipantTabNames.Personal);
    watch(activeTab, () => {
      router.replace({ query: { tab: activeTab.value } });
    });

    const showParticipantWebApplication = computed(
      () => applicationInfo.value && applicationForm.value && contact.value
    );

    async function setApplicationData() {
      if (!userAppliedViaForm) {
        return;
      }
      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          const appFormResponse = await restrictedAccessApi.getAppFormResponse(
            +route.params.id,
            +route.params.participantId
          );
          if (!appFormResponse.data) {
            return;
          }
          applicationInfo.value = appFormResponse.data;

          const appFormTemplateResponse = await restrictedAccessApi.getAppFormTemplate(
            applicationInfo.value?.templateId
          );
          if (!appFormTemplateResponse.data) {
            return;
          }
          applicationForm.value = appFormTemplateResponse.data;
        } catch {
          openNotification(store, NotificationItemType.Error, "Feil under lesing av application form");
        }
      });
    }

    const getCustomerPerson = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        contact.value = (await restrictedAccessApi.getCustomerPerson(+route.params.participantId)).data;
      });
    };

    const navigateToParticipants = () =>
      router.push({
        name: SingleCourseRouteNames.CourseParticipants,
      });

    onMounted(async () => {
      await Promise.all([getCustomerPerson(), setApplicationData()]);
    });

    return {
      activeTab,
      contact,
      applicationInfo,
      applicationForm,
      userAppliedViaForm,
      showParticipantWebApplication,
      navigateToParticipants,
      CourseParticipantTabNames,
    };
  },
});

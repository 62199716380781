







import { defineComponent } from "@vue/composition-api";
import { mapFields } from "vuex-map-fields";

export default defineComponent({
  name: "PlanTestsStep",
  computed: {
    ...mapFields("execution", ["executionPlan.testsRequired", "executionPlan.descriptionRequired"]),
  },
});

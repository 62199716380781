







import { defineComponent } from "@vue/composition-api";
import { mapFields } from "vuex-map-fields";

export default defineComponent({
  name: "PlanEconomicsStrep",
  computed: {
    ...mapFields("execution", ["executionPlan.economyRequired"]),
  },
});

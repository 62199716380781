var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTableFiltered',{staticClass:"mt-3",attrs:{"item-key":"courseId","headers":_vm.headers,"items":_vm.coursesWithLowAttendance,"search":_vm.search},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"}},[_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Tilbake til arbeidsflate","icon":"mdi-arrow-left"},on:{"handleClick":function($event){return _vm.$router.push({ name: _vm.DashboardRouteNames.Home })}}}),_vm._v("Kurs med lavt fremmøte ")],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk i kurs","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.courseParticipantsWithLowAttendance",fn:function(ref){
var item = ref.item;
return _vm._l((item.courseParticipantsWithLowAttendance),function(participantId,i){return _c('v-chip',{key:i,staticClass:"mr-3",attrs:{"label":""},on:{"click":function($event){return _vm.navigateToParticipantAttendance(item.courseId, participantId)}}},[_vm._v(" "+_vm._s(_vm.getParticipantName(participantId))+" ")])})}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":("displayCourseAttendance-" + (item.id))},on:{"click":function($event){return _vm.displayCourseAttendance(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-open-in-new")])]}}],null,true)},[_c('span',[_vm._v("Vis fremmøte")])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }